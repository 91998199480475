import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTelegram } from "../../utils/Telegram.Provider";
import EditIcon from "@mui/icons-material/Edit";
import { useGetProfile } from "../../api/hooks/profiles/useGetProfile";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { Link, useNavigate } from "react-router-dom";
import ProfilePhoto from "./components/ProfilePicture";
import ThemedButton from "../../components/Custom/Button";
import { useDeleteProfile } from "../../api/hooks/profiles/useDeleteProfile";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

const useStyles = makeStyles({
  headerImage: {
    width: "100%",
    height: "auto",
  },
  profilePicture: {
    width: "150px",
    height: "150px",
    borderRadius: "50%",
  },
  profileContainer: {
    width: "max-content",
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    justifyContent: "center",
    alignItems: "center",
  },
  detailsContainer: {
    padding: "16px",
  },
  detailsCard: {
    padding: "16px",
    backgroundColor: "#121212",
    color: "#FFFFFF",
    borderRadius: "24px",
  },
  detailsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  details: {
    paddingTop: "8px",
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  divider: {
    backgroundColor: "white",
    height: "1px",
    marginTop: "16px !important",
    marginBottom: "16px !important",
    marginLeft: "12% !important",
    marginRight: "12% !important",
  },
  textHelper: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "16px",
    color: "#FFB6EC",
  },
  footerContainer: {
    padding: "4px 16px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  termsLink: {
    color: "white",
    textDecoration: "none",
  },
});

function ProfilePage() {
  const classes = useStyles();
  const webApp = useTelegram();
  const navigate = useNavigate();

  const onBackClick = React.useCallback(() => {
    navigate("/");
  }, [navigate]);

  React.useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(onBackClick);
      webApp.BackButton.show();
    }
  }, [webApp, onBackClick]);

  const [isImageLoading, setImageLoading] = React.useState<boolean>(true);

  const { data, isLoading } = useGetProfile(webApp?.initDataUnsafe?.user?.id);

  const [dialogOpened, setDialogOpened] = React.useState<boolean>(false);

  const { mutate: deleteProfile, isLoading: deletionInProgress } =
    useDeleteProfile({
      onSuccess: () => {
        setDialogOpened(false);
        onBackClick();
      },
    });

  const onDeleteConfirm = React.useCallback(() => {
    if (webApp?.initDataUnsafe?.user?.id) {
      deleteProfile({ telegramId: webApp?.initDataUnsafe?.user?.id });
    }
  }, [deleteProfile, webApp]);

  const handleResumeDownload = React.useCallback(() => {
    if (!data?.profile || !webApp) {
      return;
    }
    webApp.downloadFile({
      url: `${process.env.REACT_APP_SERVER_URL}/api/profiles/${data.profile.id}/resume`,
      file_name: `${data.profile.firstName}_${data.profile.lastName}_resume.pdf`,
    });
  }, [data, webApp]);

  if (isLoading || isImageLoading) {
    // A hacky way to avoid layout shift when image is loading
    return (
      <>
        <LoadingIndicator />;
        <img
          src="assets/images/profile.png"
          alt="Descriptive Alt Text"
          style={{ display: "none" }}
          onLoad={() => setImageLoading(false)}
        />
      </>
    );
  }

  // TODO: Fix image loading & layout shift
  return (
    <>
      <Box>
        <Box sx={{ position: "relative", display: "inline-block" }}>
          <img
            src="assets/images/profile.png"
            alt="Descriptive Alt Text"
            className={classes.headerImage}
            onLoad={() => setImageLoading(false)}
          />
          <Box className={classes.profileContainer}>
            <Box className={classes.profilePicture}>
              <ProfilePhoto
                telegramId={webApp?.initDataUnsafe?.user?.id! || 6301103029}
                userName={webApp?.initDataUnsafe?.user?.first_name! || "Najib"}
              />
            </Box>
            <Typography variant="h4">
              {webApp?.initDataUnsafe?.user?.username || "Create Your Profile"}
            </Typography>
          </Box>
        </Box>
        {!data?.profile && (
          <Box className={classes.textHelper}>
            <Typography variant="body1" sx={{}}>
              Fill in your profile for one click apply
            </Typography>
          </Box>
        )}
        <Box className={classes.detailsContainer}>
          <Box className={classes.detailsCard}>
            <Box className={classes.detailsHeader}>
              <Typography variant="h6">Profile Information</Typography>
              <IconButton
                color="inherit"
                onClick={() => navigate("/profile/edit")}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.details}>
              <Box
                style={{
                  display: "flex",
                  gap: "96px",
                  alignItems: "center",
                }}
              >
                <InformationBox
                  label={"First Name"}
                  value={data?.profile?.firstName || ""}
                />
                <InformationBox
                  label={"Last Name"}
                  value={data?.profile?.lastName || ""}
                />
              </Box>
              <InformationBox
                label={"Email"}
                value={data?.profile?.email || ""}
              />
              <InformationBox
                label={"X Profile"}
                value={data?.profile?.xProfile || ""}
              />
              <InformationBox
                label={"Github, LinkedIn or Portfolio URL"}
                value={data?.profile?.portfolio || ""}
              />
              <InformationBox
                label={"About Me"}
                value={data?.profile?.aboutMe || ""}
              />
            </Box>
            {data?.profile && (
              <Box onClick={handleResumeDownload}>
                <Typography style={{ marginBottom: "2px" }} variant="body1">
                  View your resume
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <Box className={classes.footerContainer}>
          <ThemedButton
            style={{ maxWidth: "max-content" }}
            onClick={() => setDialogOpened(true)}
          >
            Delete profile
          </ThemedButton>
          <Link
            className={classes.termsLink}
            to={"https://telegram.org/privacy-tpa"}
            target="_blank"
          >
            Terms and conditions
          </Link>
        </Box>
      </Box>
      <ConfirmationDialog
        open={dialogOpened}
        isLoading={deletionInProgress}
        onClose={() => setDialogOpened(false)}
        onConfirm={onDeleteConfirm}
      />
    </>
  );
}

const useHelperStyles = makeStyles({
  textBox: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  value: {
    color: "#AEAEAE",
  },
});

const InformationBox = ({ label, value }: { label: string; value: string }) => {
  const classes = useHelperStyles();
  return (
    <Box className={classes.textBox}>
      <Typography variant="body1">{label}</Typography>
      <Typography className={classes.value} variant="body1">
        {value}
      </Typography>
    </Box>
  );
};

const ConfirmationDialog = ({
  open,
  isLoading,
  onConfirm,
  onClose,
}: {
  open: boolean;
  isLoading: boolean;
  onConfirm: () => void;
  onClose: () => void;
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Confirm profile deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to proceed? By proceeding, your profile will be
          permanently deleted.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={onConfirm} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProfilePage;
