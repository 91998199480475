import React from "react";
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../utils/Telegram.Provider";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "2px",
  },
  banner: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    textAlign: "center",
    marginTop: "48px",
    background: "linear-gradient(to right, #000000, #4F0069)",
    borderRadius: "16px",
    padding: "16px 32px",
    marginLeft: "16px",
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
      padding: "8px",
    },
  },
  imageBanner: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    textAlign: "center",
    marginTop: "32px",
    marginLeft: "16px",
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "45%",
    marginLeft: "16px",
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
      padding: "8px",
    },
  },
  copyBox: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    margin: "16px 0px 0px 10px",
    borderRadius: "15px",
    textAlign: "center",
    background: "linear-gradient(to right, #14001A, #4F0069)",
    padding: "4px",
  },
  shareBox: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    margin: "16px 0px 0px 0px",
    borderRadius: "15px",
    textAlign: "center",
    background: "linear-gradient(to right, #14001A, #4F0069)",
    padding: "4px",
  },
  disclaimer: {
    marginTop: "32px",
    borderRadius: "16px",
    padding: "16px",
    background: "linear-gradient(to right, #1F003A, #3D0074)",
    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
    },
    marginLeft: "16px",
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
      padding: "8px",
    },
  },
  hr: {
    height: "1px",
    marginLeft: "10%",
    marginRight: "10%",
    marginTop: "30px",
    backgroundColor: "#A020F0",
    color: "#A020F0",
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  typography: {
    fontSize: "1rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
}));

function ReferralPage() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [copied, setCopied] = React.useState<boolean>(false);

  const webApp = useTelegram();
  const navigate = useNavigate();

  const onBackClick = React.useCallback(() => {
    navigate("/");
  }, [navigate]);

  React.useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(onBackClick);
      webApp.BackButton.show();
    }
  }, [webApp, onBackClick]);

  const handleCopy = async () => {
    const miniAppUrl = `https://t.me/${process.env.REACT_APP_BOT_NAME}/${process.env.REACT_APP_MINIAPP_NAME}`;
    await navigator.clipboard.writeText(miniAppUrl);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleShare = () => {
    const miniAppUrl = `https://t.me/${process.env.REACT_APP_BOT_NAME}/${process.env.REACT_APP_MINIAPP_NAME}`;
    const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(
      miniAppUrl
    )}&text=${encodeURIComponent("Check out this awesome Miniapp!")}`;

    window.open(telegramShareUrl, "_blank");
  };

  return (
    <Box className={classes.container}>
      {/* Banner Section */}
      <Box className={classes.banner}>
        <Typography
          variant={isMobile ? "h6" : "h5"}
          sx={{
            fontWeight: "bold",
            fontSize: "30px",
            fontFamily: "Helvetica Neue",
          }}
        >
          Rewards & Bounties
        </Typography>
      </Box>

      {/* Image Banner Section */}
      <Box className={classes.imageBanner}>
        <img
          src={"/assets/images/comingsoon.png"}
          alt="coming-soon"
          style={{ width: isMobile ? "100%" : "100%", margin: "0 auto" }}
        />
      </Box>

      <Box className={classes.disclaimer}>
        <Typography
          variant={isMobile ? "h6" : "h5"}
          sx={{
            paddingBottom: "8px",
            fontFamily: "Helvetica Neue",
            fontSize: "20px",
          }}
        >
          About bounties
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: "Helvetica Neue" }}>
          Coming very soon, you will be able to share jobs and refer your
          friends to get token rewards and bounties ✨
        </Typography>
      </Box>

      <hr className={classes.hr} />

      <Box className={classes.buttonsContainer}>
        <Box className={classes.shareBox}>
          <IconButton
            color="inherit"
            onClick={handleShare}
            sx={{ height: "55px" }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "16px", sm: "18px", md: "20px" },
                fontFamily: "Helvetica Neue",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}
            >
              Share App ➡️
            </Typography>
          </IconButton>
        </Box>

        <Box className={classes.copyBox}>
          <IconButton color="inherit" onClick={handleCopy}>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "16px", sm: "18px", md: "20px" },
                fontFamily: "Helvetica Neue",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}
            >
              {!copied ? "Copy Link 🔗" : "Copied."}
            </Typography>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default ReferralPage;
