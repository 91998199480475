import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import HomeIcon from "./NavigationIcons/HomeIcon";
import JobSearchIcon from "./NavigationIcons/JobSearchIcon";
import ReferralIcon from "./NavigationIcons/ReferralIcon";
import ProfileIcon from "./NavigationIcons/ProfileIcon";

const useStyles = makeStyles({
  container: {
    bottom: "0",
    position: "fixed",
    width: "100%",
    backgroundColor: "#660872",
    zIndex: 1000,
    paddingTop: "2px",
    paddingLeft: "4px",
    paddingRight: "4px",
    paddingBottom: "16px",
    display: "flex",
    justifyContent: "center",
  },
  "@keyframes animateGradient": {
    "0%": {
      backgroundPosition: "0% 0%",
    },
    "100%": {
      backgroundPosition: "200% 200%",
    },
  },
  navigationBar: {
    background: "linear-gradient(to right, #1E1E1E, #000000)",
    borderRadius: "12px",
    border: "1px solid rgb(212, 206, 206)",
    width: "95%",
    color: "white",
    marginBottom: "4px",
    paddingTop: "8px",
    paddingBottom: "8px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    animation: "$animateGradient 3s linear infinite",
    backgroundSize: "200% 200%",
  },
  tab: {
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "4px",
    borderRadius: "8px",
    color: "rgba(255, 255, 255, 0.4)",
  },
  labeledIcon: {
    display: "flex",
    gap: "2px",
    width: "48px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

function NavigationBar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  // Function to check if the current path matches the button's path
  const isSelected = (path: string) => {
    if (path === "/") {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path);
  };

  const trackPageView = async (pageName: string) => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;

    try {
      const response = await fetch(
        `${serverUrl}/api/page-views/track?pageName=${pageName}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to track page view");
      }
    } catch (error) {
      console.error("Error tracking page view", error);
    }
  };

  const handleMenuClick = (path: string, pageName: string) => {
    trackPageView(pageName); // Call the API to track the page view
    navigate(path); // Navigate to the corresponding page
  };

  const navBarIcons = [
    { component: HomeIcon, path: "/", displayName: "Home", pageName: "HOME" },
    {
      component: JobSearchIcon,
      path: "/openings",
      displayName: "Jobs",
      pageName: "JOBS",
    },
    {
      component: ReferralIcon,
      path: "/referral",
      displayName: "Friends",
      pageName: "HUB",
    },
    {
      component: ProfileIcon,
      path: "/profile",
      displayName: "Profile",
      pageName: "PROFILE",
    },
  ];

  return (
    <Box className={classes.container}>
      <Box className={classes.navigationBar}>
        {navBarIcons.map(
          ({ component: IconComponent, pageName, path, displayName }) => (
            <Box
              key={pageName}
              className={`${classes.tab} ${isSelected(path) ? "selected" : ""}`}
              onClick={() => handleMenuClick(path, pageName)}
            >
              <div className={classes.labeledIcon}>
                <IconComponent
                  color={isSelected(path) ? "#FFFFFF" : undefined}
                />
                <Typography
                  variant="body2"
                  style={{
                    fontSize: "11px",
                    color: isSelected(path) ? "#FFFFFF" : undefined,
                  }}
                >
                  {displayName}
                </Typography>
              </div>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
}

export default NavigationBar;
