import { useQuery } from "react-query";
import fetcher from "../../base-fetcher";

export interface Tip {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  createdAt: string;
}

const fetchLatestTips = async (): Promise<Tip[]> =>
  fetcher({
    resource: "api/tips/latest",
    method: "GET",
  });

export const useFetchLatestTips = () => {
  return useQuery<Tip[], Error>("latestTips", fetchLatestTips, {
    staleTime: 60 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
  });
};
