import React from "react";

interface JobSearchIconProps {
  color?: string;
  width?: number;
  height?: number;
}

const JobSearchIcon: React.FC<JobSearchIconProps> = ({
  color = "#7A7A7A",
  width = 30,
  height = 30,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1758 26.4997H11.3065C6.5265 26.4997 4.13717 26.4997 2.65184 24.9863C1.1665 23.473 1.1665 21.037 1.1665 16.1663C1.1665 11.2957 1.1665 8.85967 2.65184 7.34634C4.13717 5.83301 6.5265 5.83301 11.3065 5.83301H16.3772C21.1572 5.83301 23.5478 5.83301 25.0332 7.34634C26.1758 8.51034 26.4385 10.221 26.4998 13.1663"
        stroke={color}
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.189 25.1972L27.833 27.8332M19.833 5.83317L19.6997 5.41984C19.0397 3.3665 18.7103 2.33984 17.925 1.75317C17.1383 1.1665 16.0957 1.1665 14.0063 1.1665H13.6557C11.569 1.1665 10.525 1.1665 9.73967 1.75317C8.95301 2.33984 8.62367 3.3665 7.96367 5.41984L7.83301 5.83317M26.5703 21.8678C26.5809 21.2437 26.467 20.6237 26.2354 20.044C26.0038 19.4644 25.6592 18.9366 25.2215 18.4915C24.7838 18.0464 24.2619 17.6929 23.6862 17.4516C23.1105 17.2103 22.4926 17.086 21.8683 17.086C21.2441 17.086 20.6261 17.2103 20.0504 17.4516C19.4747 17.6929 18.9528 18.0464 18.5152 18.4915C18.0775 18.9366 17.7328 19.4644 17.5013 20.044C17.2697 20.6237 17.1558 21.2437 17.1663 21.8678C17.1871 23.1012 17.6916 24.277 18.5712 25.1418C19.4507 26.0067 20.6348 26.4913 21.8683 26.4913C23.1019 26.4913 24.286 26.0067 25.1655 25.1418C26.0451 24.277 26.5496 23.1012 26.5703 21.8678Z"
        stroke={color}
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default JobSearchIcon;
